import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
//Datatable Modules
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import ic_edit from "../assets/images/ic_edit.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import Modal from "react-bootstrap/Modal";
import Pagination from "./Pagination";
import { PostCallWithErrorResponse, simpleGetCall } from "../api/ApiServices";
import Loader from "./Loader";
import { BASE_URL } from "../api/ApiConfig";
import { ToastMsg } from "./ToastMsg";
const ReportManagement = () => {
  const { sidebar, useDebounce } = useContext(AppContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [add, setAdd] = useState(false);
  const handleAddClose = () => setAdd(false);

  const [currrentpage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState();
  const [search, setSearch] = useState("");
  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const [lastPage, setLastPage] = useState();
  const [viewModal, setView] = useState(false);

  const [report, setReport] = useState({});
  const handleViewClose = () => setView(false);
  const handleViewShow = (report) => {
    setReport({
      reportedUser: report.reportedUser?.fullName,
      reporter: report.reporter?.fullName,
      message: report.message,
      status: report.status,
      adminComment: report.adminComment,
      image: report.image,
    });
    setView(true);
  };

  const handleAddShow = (report) => {
    console.log("report edit", report);
    setReport({
      reportId: report._id,
      reportedUser: report.reportedUser?.fullName,
      reporter: report.reporter?.fullName,
      message: report.message,
      status: report.status,
      adminComment: report.adminComment,
      image: report.image,
    });
    setAdd(true);
  };

  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("report edited", report);
    const response = await PostCallWithErrorResponse(
      BASE_URL + `/admin/report/manage`,
      JSON.stringify(report)
    );
    console.log("response edit", response);
    if (!response.error) {
      ToastMsg("success", response.json.message);
      handleAddClose();
      getUserListApi(search, currrentpage);
    } else {
      console.log("error", response.error);
      ToastMsg("error", response.json.message);
    }
  };

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    getUserListApi(debouncedSearchTerm, currrentpage);
  }, [debouncedSearchTerm]);

  const getUserListApi = (search, currrentpage) => {
    setLoading(true);
    simpleGetCall(
      BASE_URL + `/admin/reports?page=${currrentpage}&search=${search}`
    ).then((res) => {
      if (!res.error) {
        setLoading(false);
        setReportList(res.data);
        if (res.data.length) {
          const totalPage = Number(res.total_pages);
          setTotalPages(totalPage);
          setTotalPages(1);
          setCurrentPage(1);
          setTotalCount(res.data.length);
          setLastPage(res.last_page);
        }
      } else {
        setReportList([]);
      }
    });
  };

  //Pagination

  const searchedDataPageCounts =
    reportList &&
    reportList.length &&
    reportList.filter((item) =>
      item.ProjectName?.toLowerCase().includes(search)
    );

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected + 1);
    getUserListApi("", selected + 1);
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Report Management</label>
        </div>
      </div>
      {/* ======================= START ADD ========================== */}
      <Modal
        show={add}
        onHide={handleAddClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <Form.Label>Reported User</Form.Label>
                <Form.Control
                  type="text"
                  value={report.reportedUser}
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Reported User
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Label>Reported By</Form.Label>
                <Form.Control type="text" value={report.reporter} disabled />
                <Form.Control.Feedback type="invalid">
                  Please Enter Reporter
                </Form.Control.Feedback>
              </div>

              <div className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control type="text" value={report.message} disabled />
                <Form.Control.Feedback type="invalid">
                  Please Enter Reason
                </Form.Control.Feedback>
              </div>

              {report.image && (
                <div className="mb-3">
                  <Form.Label>Image</Form.Label>
                  <Form.Control type="text" value={report.image} disabled />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Reason
                  </Form.Control.Feedback>
                </div>
              )}

              <div className=" mb-3">
                <Form.Label>Admin Comment</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  value={report.adminComment}
                  onChange={(e) =>
                    setReport({
                      ...report,
                      adminComment: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Comment
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 mb-3">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  aria-label="Select Status"
                  className="mb-3"
                  value={report.status}
                  onChange={(e) =>
                    setReport({
                      ...report,
                      status: e.target.value,
                    })
                  }
                >
                  <option value="OPENED">OPENED</option>
                  <option value="PENDING">PENDING</option>
                  <option value="RESOLVED">RESOLVED</option>
                  <option value="CLOSED">CLOSED</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please Select Status
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="buttons-modal border-top pt-3 text-end ">
              <button className="me-2" type="submit">
                Save
              </button>
              <Link to="#" className=" bg-danger" onClick={handleAddClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END ADD ========================== */}

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to Temporary Report?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button className="me-2 bg-danger" type="submit">
              Report
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}

      {/* ======================= VIEW ========================== */}
      <Modal
        show={viewModal}
        onHide={handleViewClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Report Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Reported User
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {report.reportedUser}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Reporter
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {report.reporter}
                  </label>
                </div>
              </div>
              <div className=" mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Message
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {report.message}
                  </label>
                </div>
              </div>
              {report.image && (
                <div className=" mb-3">
                  <div>
                    <label htmlFor="" className="key">
                      Image
                    </label>
                  </div>
                  <div>
                    <img src={report.image} alt="report"></img>
                  </div>
                </div>
              )}

              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Status
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {report.status}
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label htmlFor="" className="key">
                    Admin Comment
                  </label>
                </div>
                <div>
                  <label htmlFor="" className="value">
                    {report.adminComment
                      ? report.adminComment
                      : "No Comments Yet"}
                  </label>
                </div>
              </div>
            </div>
            <div className="buttons-modal border-top pt-3 text-end ">
              <Link to="#" className="bg-danger" onClick={handleViewClose}>
                Cancel
              </Link>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ======================= END VIEW ========================== */}

      <div className="table-wrapper">
        <div className="search-wrapper row">
          <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Search"
              onChange={(e) => {
                setSearch(e.target.value);
                setCurrentPage(1);
              }}
            />
          </Form.Group>
        </div>
        {loading ? (
          <div style={{ marginTop: "150px" }}>
            <Loader />
          </div>
        ) : (
          <div className="main-data-wrapper">
            <Table class="display" striped bordered hover>
              <thead>
                <tr>
                  <th> </th>
                  <th>Reported By</th>
                  <th>Reported User</th>
                  <th>Reason</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {reportList && reportList.length > 0 ? (
                  reportList
                    .slice()
                    .reverse()
                    .map((repo, index) => {
                      return (
                        <tr key={"repo" + index}>
                          <td>{(currrentpage - 1) * 10 + index + 1}</td>
                          <td>{repo.reporter?.fullName}</td>
                          <td>{repo.reportedUser?.fullName}</td>
                          <td>{repo.message}</td>
                          <td>{repo.status}</td>
                          <td className="action">
                            <span>
                              <Link
                                to="#"
                                className="icon edit"
                                onClick={() => {
                                  handleAddShow(repo);
                                }}
                              >
                                <img src={ic_edit} alt="" />
                              </Link>
                            </span>
                            <Link
                              to="#"
                              className="icon view"
                              onClick={() => {
                                handleViewShow(repo);
                              }}
                            >
                              <img src={ic_eyes} alt="" />
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <div className=" justify-content-center align-items-center text-danger text-center mt-3">
                        Data Not Found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )}
        {reportList.length > 0 ? (
          <div className="pagination-wrapper">
            <div className="entry-number">
              <label htmlFor="">
                Showing {(currrentpage - 1) * 10 + 1} -{" "}
                {lastPage == true ? totalCount : currrentpage * 10} of{" "}
                {totalCount}
              </label>
            </div>
            <Pagination
              data={reportList}
              pageChangeHandler={pageChangeHandler}
              currentPage={currrentpage}
              searchValue={search}
              totalPages={totalPages}
              searchedDataPageCounts={searchedDataPageCounts}
            />
          </div>
        ) : null}
      </div>
    </main>
  );
};

export default ReportManagement;
