import { CartesianGrid, Line, LineChart, XAxis } from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "./components/chart";
import { format, isValid, parseISO } from "date-fns";

const chartData = [
  { month: "January", free: 186},
  { month: "February", free: 305},
  { month: "March", free: 237},
  { month: "April", free: 73},
  { month: "May", free: 209},
  { month: "June", free: 214},
];

const chartConfig = {
  free: {
    label: "Free",
    color: "hsl(var(--chart-1))",
  }
};

export function SingleLineChartComponent({chartData, filterTime}) {

  const formatTime = (time) => {
    if (filterTime === "Daily") {
      const date = parseISO(time);
      return isValid(date) ? format(date, "MM/dd") : time;
    } else if (filterTime === "Weekly") {
      return time; // Assuming the weekly format is already suitable
    } else if (filterTime === "Monthly") {
      // const [year, month] = time.split('-');
      // return `${year}-${String(month).padStart(2, '0')}`; // Format as "yyyy-MM"
      const [year, month] = time.split('-');
      const date = new Date(year, month - 1);
      return isValid(date) ? format(date, "MMM") : time; 
    }
    return time;
  };

  return (
    <ChartContainer config={chartConfig} style={{ aspectRatio: 'auto', maxHeight:'450px', height: '100%' }}>
      <LineChart
        accessibilityLayer
        data={chartData}
        margin={{
          left: 12,
          right: 12,
        }}
        style={{maxHeight: '450px'}}
      >
        <CartesianGrid vertical={false}/>
        <XAxis
          dataKey="time"
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          // tickFormatter={(value) => value.slice(0, 5)}
          tickFormatter={formatTime}
        />
        <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
        <Line
          dataKey="total"
          type="monotone"
          stroke="var(--chart-3)"
          strokeWidth={2}
          dot={false}
        />
        <Line
          dataKey="male"
          type="monotone"
          stroke="var(--chart-2)"
          strokeWidth={2}
          dot={false}
        />
        <Line
          dataKey="female"
          type="monotone"
          stroke="var(--chart-1)"
          strokeWidth={2}
          dot={false}
        />
      </LineChart>
    </ChartContainer>
  );
}
