import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import Loader from "./Loader";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import { simpleGetCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { PieChartComponent } from "../charts/PieChartComponent";
import { BarChartComponent } from "../charts/BarChartComponent";
import { LineChartComponent } from "../charts/LineChartComponent";
import { ActiveuserChart } from "./ActiveUserChart";
import "../assets/styles/chart.css";
import "../assets/styles/variable.css";
import HeatMap from "react-heatmap-grid";
import { MultipleBarChartComponent } from "../charts/MultipleBarChartComponent";

const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const formatMonth = (value) => monthNames[value - 1] || value;

const formatDate = (value) => value?.slice(0, 10);

const transformCohortData = (cohortData) => {
  // Extract unique signup months and years for x-axis
  const xLabels = Array.from(new Set(cohortData.map(item => `${item.signupMonth}`)));
  
  // Extract unique retention months and years for y-axis
  const yLabels = Array.from(new Set(cohortData.map(item => `${item.retentionMonth}`)));

  // Create a matrix to store retention rates
  const heatmapData = yLabels.map((yLabel) =>
    xLabels.map((xLabel) => {
      // Find the corresponding data point
      const dataPoint = cohortData.find(
        (item) => `${item.signupMonth}` === xLabel &&
                  `${item.retentionMonth}` === yLabel
      );
      // Return the retention rate or 0 if not found
      return dataPoint ? dataPoint.retentionRate : 0;
    })
  );

  return { xLabels, yLabels, heatmapData };
};


const DashboardAnalytics = () => {
  const { sidebar } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [genderCount, setGenderCount] = useState([]);
  const [countryCount, setCountryCount] = useState([]);
  const [membershipCount, setMembershipCount] = useState([]);
  const [membershipData, setMembershipData] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [retentionData, setRetentionData] = useState([]);
  const [churnData, setChurnData] = useState([]);
  const [cohortData, setCohortData] = useState([]);
  const [xLabels, setxLabels] = useState([]);
  const [yLabels, setyLabels] = useState([]);
  const [heatmapData, setHeatmapData] = useState([]);

  useEffect(() => {
    setLoading(true);
    simpleGetCall(ApiConfig.USERS_LIST).then((res) => {
      if (res) {
        setLoading(false);
        setUsers(res.data);
      }
    });
    simpleGetCall(ApiConfig.ACTIVE_USER_COUNT).then((res) => {
      if (res) {
        setLoading(false);
        setActiveUsers(res.data);
      }
    });
    simpleGetCall(ApiConfig.CAMPAIGN_LIST)
      .then((res) => {
        const formattedData = res.data.map((campaign) => ({
          campaignName: campaign.campaignName,
          Conversions: campaign.totalConversions,
          totalSpend: campaign.totalSpend,
          revenueGenerated: campaign.revenueGenerated,
          CPA: campaign.totalConversions
            ? campaign.totalSpend / campaign.totalConversions
            : 0,
          ROI: campaign.totalSpend
            ? (campaign.revenueGenerated - campaign.totalSpend) /
              campaign.totalSpend
            : 0,
        }));
        setCampaigns(formattedData);
      })
      .catch((error) => console.error("Error fetching campaign data:", error));
      simpleGetCall(ApiConfig.USER_RETENTION)
      .then((res) => {
        setRetentionData(res.data.retentionData);
        setChurnData(res.data.churnData);
        // setCohortData(res.data.cohortData);
        const data = res.data.cohortData;
        const { xLabels, yLabels, heatmapData } = transformCohortData(data);
        setCohortData(data);
        setxLabels(xLabels);
        setyLabels(yLabels);
        setHeatmapData(heatmapData);
      })
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      const ageCounts = categorizeUsersByAge(users);
      setChartData(transformAgeCountsToChartData(ageCounts));
      const genderCounts = calculateGenderCounts(users);
      setGenderCount(transformGenderCountsToChartData(genderCounts));
      const countryCounts = calculateCountryCounts(users);
      setCountryCount(transformCountryCountsToChartData(countryCounts));
      const membershipCounts = calculateMembershipCounts(users);
      setMembershipCount(
        transformMembershipCountsToChartData(membershipCounts)
      );
      setMembershipData(
        transformMembershipCountsToBarChartData(membershipCounts)
      );
    }
  }, [users]);

  const calculateAge = (dateOfBirth) => {
    const birthYear = new Date(dateOfBirth).getFullYear();
    return new Date().getFullYear() - birthYear;
  };

  const categorizeUsersByAge = (users) => {
    const ageRanges = {
      "18-24": { male: 0, female: 0 },
      "25-34": { male: 0, female: 0 },
      "35-44": { male: 0, female: 0 },
      "45-54": { male: 0, female: 0 },
      "55-64": { male: 0, female: 0 },
      "65+": { male: 0, female: 0 },
  };

    users.forEach((user) => {
      const age = calculateAge(user.dateOfBirth);
      const gender = user.gender ? user.gender.toLowerCase() : 'unknown';

      if (gender !== 'male' && gender !== 'female') {
        return; // Skip users with undefined or unknown gender
    }

      if (age >= 18 && age <= 24) {
        ageRanges["18-24"][gender]++;
      } else if (age >= 25 && age <= 34) {
        ageRanges["25-34"][gender]++;
      } else if (age >= 35 && age <= 44) {
        ageRanges["35-44"][gender]++;
      } else if (age >= 45 && age <= 54) {
        ageRanges["45-54"][gender]++;
      } else if (age >= 55 && age <= 64) {
        ageRanges["55-64"][gender]++;
      } else if (age >= 65) {
        ageRanges["65+"][gender]++;
      }
    });

    return ageRanges;
  };

  const transformAgeCountsToChartData = (ageCounts) => {
    return Object.keys(ageCounts).map((ageRange) => ({
      age: ageRange,
        male: ageCounts[ageRange].male,
        female: ageCounts[ageRange].female,
    }));
  };

  const calculateGenderCounts = (users) => {
    const genderCounts = {
      male: 0,
      female: 0,
      other: 0,
    };

    users.forEach((user) => {
      if (user.gender in genderCounts) {
        genderCounts[user.gender]++;
      } else {
        genderCounts.other++;
      }
    });

    return genderCounts;
  };

  const transformGenderCountsToChartData = (genderCounts) => {
    return Object.keys(genderCounts)
      .map((gender) => ({
        gender:gender,
        users: genderCounts[gender],
        fill:
          gender === "male"
            ? "var(--chart-2)"
            : gender === "female"
            ? "var(--chart-1)"
            : gender === "other"
            ? "var(--chart-3)"
            : "var(--chart-4)"
      }))
      .filter((item) => item.users > 0);
  };

  const calculateCountryCounts = (users) => {
    const countryCounts = {};

    users.forEach((user) => {
      let country = user.travelMode.country;
      const gender = user.gender ? user.gender.toLowerCase() : 'unknown';

      if (gender !== 'male' && gender !== 'female') {
        return; // Skip users with undefined or unknown gender
    }

      if (country) {
        if (country.includes(",")) {
          country = country.split(",")[1]?.trim() || "Other";
        }
      } else {
        country = "Other";
      }

      if (!(country in countryCounts)) {
        countryCounts[country] = { male: 0, female: 0 };
    }

    countryCounts[country][gender]++;
    });

    return countryCounts;
  };

  const transformCountryCountsToChartData = (countryCounts) => {
    // Convert the countryCounts object to an array
    const data = Object.keys(countryCounts).map((country) => ({
      category: country,
        male: countryCounts[country].male,
        female: countryCounts[country].female,
    }));

    // Sort the data so that "Other" is the last element
    data.sort((a, b) => {
      if (a.category === "Other") return 1;
      if (b.category === "Other") return -1;
      return a.category.localeCompare(b.category);
    });

    return data;
  };

  const calculateMembershipCounts = (users) => {
    const membershipCounts = {
      FREE: { male: 0, female: 0 },
      PREM: { male: 0, female: 0 },
      PREMPLUS: { male: 0, female: 0 },
  };

    users.forEach((user) => {
      const plan = user.subscriptionPlan;
      const gender = user.gender ? user.gender.toLowerCase() : 'unknown';
      
      if (plan in membershipCounts && (gender === 'male' || gender === 'female')) {
        membershipCounts[plan][gender]++;
      } else {
        membershipCounts.OTHER = (membershipCounts.OTHER || 0) + 1;
      }
    });

    return membershipCounts;
  };

  const transformMembershipCountsToChartData = (membershipCounts) => {
    return Object.keys(membershipCounts)
      .map((plan) => ({
        plan,
        users: membershipCounts[plan],
        fill:
          plan === "FREE"
            ? "var(--chart-3)"
            : plan === "PREM"
            ? "var(--chart-4)"
            : plan === "PREMPLUS"
            ? "var(--chart-5)"
            : "var(--chart-2)",
      }))
      .filter((item) => item.users > 0);
  };

  const transformMembershipCountsToBarChartData = (membershipCounts) => {
    return Object.keys(membershipCounts).map((plan) => ({
      plan: plan.charAt(0).toUpperCase() + plan.slice(1).toLowerCase(),
      male: membershipCounts[plan].male,
      female: membershipCounts[plan].female,
  })).filter(data => data.plan !== 'Other');
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "dashboard-main" : "cx-active dashboard-main"}
    >
      <div className="dashboard-wrapper">
        <div className="heading-common">
          <label htmlFor="">DASHBOARD</label>
        </div>
        <div className="row mt-3 main-section">
          {loading ? (
            <div style={{ marginTop: "150px" }}>
              <Loader />
            </div>
          ) : (
            <>
              <div className="col-12 ">
                <div className="row">
                  {/* <label> 01. User Demographics</label> */}

                  <div className="col-md-4 mt-3">
                    <div className="mainCards">
                      <div className="ineerCard">
                        <div className="chart-heading">
                          <label>Membership Distribution</label>
                        </div>
                        {/* Bar Chart  */}
                        {membershipData && (
                          <MultipleBarChartComponent
                            data={membershipData}
                            xAxisKey="plan"
                            dataKey1="male"
                            dataKey2="female"
                            tickFormatter={formatDate}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mt-3">
                    <div className="mainCards">
                      <div className="ineerCard">
                        <div className="chart-heading">
                          <label>Age Distribution</label>
                        </div>
                        {/* bar chart  */}
                        {chartData && (
                          <MultipleBarChartComponent
                            data={chartData}
                            xAxisKey="age"
                            dataKey1="male"
                            dataKey2="female"
                            tickFormatter={formatDate}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mt-3">
                    <div className="mainCards">
                      <div className="ineerCard">
                        <div className="chart-heading">
                          <label>Geographic Distribution</label>
                        </div>
                        {/* bar chart  */}
                        {countryCount && (
                          <MultipleBarChartComponent
                            data={countryCount}
                            xAxisKey="category"
                            dataKey1="male"
                            dataKey2="female"
                            tickFormatter={formatDate}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-3" style={{height:'520px'}}>
                    <div className="mainCards" style={{height:'100%'}}>
                      <div className="ineerCard" style={{maxHeight:'520px', height:'100%', justifyContent:'initial'}}>
                        <div className="chart-heading">
                          <label>Active Users</label>
                        </div>
                        {/* Active user chart */}
                        <ActiveuserChart activeUsers={activeUsers} />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mt-3">
                    <div className="mainCards">
                      <div className="ineerCard">
                        <div className="chart-heading">
                          <label>Gender Ratio</label>
                        </div>
                        {/* Pie Chart  */}
                        {genderCount && (
                          <PieChartComponent
                            data={genderCount}
                            dataKey="users"
                            nameKey="gender"
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mt-3">
                    <div className="mainCards">
                      <div className="ineerCard">
                        <div className="chart-heading">
                          <label>Campaign Performance</label>
                        </div>
                        {/* Bar Chart  */}
                        {campaigns && (
                          <BarChartComponent
                            data={campaigns}
                            xAxisKey="campaignName"
                            yAxisKey="Conversions"
                            tickFormatter={formatDate}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mt-3">
                    <div className="mainCards">
                      <div className="ineerCard">
                        <div className="chart-heading">
                          <label>Cost Per Acquisition (CPA)</label>
                        </div>
                        {/* Line Chart  */}
                        {campaigns && (
                          <LineChartComponent 
                          chartData={campaigns}
                          xAxisKey="campaignName"
                          yAxisKey="CPA"
                          tickFormatter={formatDate}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mt-3">
                    <div className="mainCards">
                      <div className="ineerCard">
                        <div className="chart-heading">
                          <label>Return on Investment (ROI)</label>
                        </div>
                        {/* Line Chart  */}
                        {campaigns && (
                          <LineChartComponent 
                          chartData={campaigns}
                          xAxisKey="campaignName"
                          yAxisKey="ROI"
                          tickFormatter={formatDate}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mt-3">
                    <div className="mainCards">
                      <div className="ineerCard">
                        <div className="chart-heading">
                          <label>Retention Rate</label>
                        </div>
                        {/* Line Chart  */}
                        {retentionData && (
                          <LineChartComponent 
                          chartData={retentionData}
                          xAxisKey="month"
                          yAxisKey="retentionRate"
                          tickFormatter={formatMonth}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mt-3">
                    <div className="mainCards">
                      <div className="ineerCard">
                        <div className="chart-heading">
                          <label>Churn Rate</label>
                        </div>
                        {/* Bar Chart  */}
                        {churnData && (
                          <BarChartComponent
                            data={churnData}
                            xAxisKey="month"
                            yAxisKey="churnRate"
                            tickFormatter={formatMonth}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="mainCards">
                      <div className="ineerCard">
                        <div className="chart-heading">
                          <label>Cohort Analysis</label>
                        </div>
                        {/* Bar Chart  */}
                        {cohortData && (
                          <HeatMap
                          xLabels={xLabels}
                          yLabels={yLabels}
                          data={heatmapData}
                          squares
                          height={55}
                          width={55}
                          cellRender={(value) => value && `${value}%`}
                          title={(value, unit) => `${value}`}
                        />
                        )}
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="col-12 mt-3">
                <div className="row">
                  {/* <label> 02. Membership Statistics</label> */}

                  {/* <div className="col-md-6 mt-3">
                    <div className="mainCards">
                      <div className="ineerCard">
                        <div className="chart-heading">
                          <label>Membership Distribution</label>
                        </div>
                        {membershipCount && (
                          <PieChartComponent
                            data={membershipCount}
                            dataKey="users"
                            nameKey="plan"
                          />
                        )}
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="col-md-6 mt-3">
                    <div className="mainCards">
                      <div className="ineerCard">
                        <div className="chart-heading">
                          <label>Membership Growth Over Time</label>
                        </div>
                        <LineChartComponent />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="col-12 mt-3">
                <div className="row">
                  {/* <label> 03. User Engagement</label> */}

                  

                  {/* <div className="col-md-4 mt-3">
                    <div className="mainCards">
                      <div className="ineerCard">
                        <div className="chart-heading">
                          <label>Average Session Duration</label>
                        </div>
                        <ActiveuserChart />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mt-3">
                    <div className="mainCards">
                      <div className="ineerCard">
                        <div className="chart-heading">
                          <label>Messages Sent/Matches Made</label>
                        </div>
                        <ActiveuserChart />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

            </>
          )}
        </div>
      </div>
    </main>
  );
};

export default DashboardAnalytics;
